import {Injectable} from '@angular/core';
import {ScanditProvider} from "../../../providers/scandit/scandit.provider";
import {PickingProvider} from "../../../providers/picking/picking.provider";
import {PickingStoreService} from "../../endpoint/picking-store/picking-store.service";
import {PickingStoreModel} from "../../../models/endpoints/PickingStore";
import {ScanditModel} from "../../../models/scandit/Scandit";
import {Events} from "@ionic/angular";
import {environment} from "../../../environments/environment";
import {environment as al_environment} from "../../../../../../apps/al/src/environments/environment";
import {ItemReferencesProvider} from "../../../providers/item-references/item-references.provider";
import ListItem = PickingStoreModel.ListItem;
import {TimesToastType} from "../../../models/timesToastType";
import {IntermediaryService, InventoryService} from "@suite/services";
import {PositionsToast} from "../../../models/positionsToast.type";
import {InventoryModel} from "../../../models/endpoints/Inventory";

declare let Scandit;
declare let GScandit;
declare let ScanditMatrixSimple;

@Injectable({
  providedIn: 'root'
})
export class PickingScanditService {

  private timeoutHideText;
  private packingReferences: string[];

  private packing: boolean;
  private readonly timeMillisToResetScannedCode: number = 1000;
  private lastCodeScanned: string;

  private processed;

  constructor(
    private events: Events,
    private pickingStoreService: PickingStoreService,
    private scanditProvider: ScanditProvider,
    private pickingProvider: PickingProvider,
    private itemReferencesProvider: ItemReferencesProvider,
    private intermediaryService: IntermediaryService,
    private inventoryService: InventoryService,
  ) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
  }

  async picking(packing?: boolean, title?: string, callbackOnFinish?:()=>void, callbackOnBack?:()=>void) {
    let filtersToGetProducts: PickingStoreModel.ParamsFiltered = {
      orderbys: [],
      sizes: [],
      colors: [],
      models: [],
      brands: [],
      toAssociate: packing,
      external: this.pickingProvider.subgroup && this.pickingProvider.subgroup == 'external',
      store: this.pickingProvider.subgroup && this.pickingProvider.subgroup == 'store'
    };
    let listProductsToStorePickings: ListItem[] = this.pickingProvider.listProductsToStorePickings;
    let listProductsProcessed: ListItem[] = this.pickingProvider.listProductsProcessedToStorePickings;
    let listProductsDisassociated: ListItem[] = [];
    let listRejectionReasons = this.pickingProvider.listRejectionReasonsToStorePickings;
    this.lastCodeScanned = 'start';
    this.processed = listProductsProcessed;
    this.packingReferences = [];
    this.packing = false;
    let typePacking: number = 1;
    let scannerPaused: boolean = false;
    let scanMode = 'products';
    let filtersPicking = this.pickingProvider.listFiltersPicking;
    let timeoutStarted = null;

    let textPickingStoresInit: string;
    if(packing){
      this.packing = true;
      textPickingStoresInit = 'Escanee los embalajes a usar';
      scanMode = 'carriers';
    }else{
      textPickingStoresInit = listProductsToStorePickings.length == 0 ? this.pickingProvider.literalsJailPallet[typePacking].press_scan_packings_to_continue : 'Escanea los productos a incluir';
    }
    ScanditMatrixSimple.initPickingStores(async (response: ScanditModel.ResponsePickingStores) => {
        if (response && response.result && response.actionIonic) {
          this.executeAction(response.actionIonic, response.params);
        } else if (!scannerPaused && response.result) {
          if (response.barcode && response.barcode.data && this.lastCodeScanned != response.barcode.data) {
            let codeScanned = response.barcode.data;
            if (scanMode == 'products') {
              if (this.itemReferencesProvider.checkCodeValue(codeScanned) == this.itemReferencesProvider.codeValue.PRODUCT) {
                this.lastCodeScanned = codeScanned;
                ScanditMatrixSimple.setTimeout("lastCodeScannedStart", this.timeMillisToResetScannedCode, "");

                if (listProductsToStorePickings.length > 0) {
                  let paramsPickingStoreProcess: PickingStoreModel.SendProcess = {
                    productReference: codeScanned
                  };
                  ScanditMatrixSimple.showLoadingDialog('Comprobando producto...');
                  ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/picking-store/process', paramsPickingStoreProcess, localStorage.getItem("access_token"), "postPickingStoreProcess");
                } else {
                  ScanditMatrixSimple.setText(
                    this.pickingProvider.literalsJailPallet[typePacking].scan_to_end,
                    this.scanditProvider.colorsMessage.success.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(2000);
                }
              } else {
                ScanditMatrixSimple.setText(
                  `Escanee un producto válido`,
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  18);
                this.hideTextMessage(2000);
              }
            }
            else if (scanMode == 'carriers') {
              ScanditMatrixSimple.hideLoadingDialog();
              if (this.itemReferencesProvider.checkCodeValue(codeScanned) == this.itemReferencesProvider.codeValue.PACKING) {
                if(this.packingReferences.includes(codeScanned)){
                  ScanditMatrixSimple.setText(
                    `Ya ha escaneado el embalaje ${codeScanned}`,
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    18);
                  this.hideTextMessage(2000);
                }else{
                  ScanditMatrixSimple.showLoadingDialog('Cargando embalaje...');
                  ScanditMatrixSimple.setTimeout("scannedPacking", 1000, JSON.stringify([codeScanned]));
                }
              } else {
                ScanditMatrixSimple.setText(
                  `Escanee un embalaje válido`,
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  18);
                this.hideTextMessage(2000);
              }
            }
            else if (scanMode == 'products_disassociate') {
              if (this.itemReferencesProvider.checkCodeValue(codeScanned) == this.itemReferencesProvider.codeValue.PRODUCT) {
                this.lastCodeScanned = codeScanned;
                ScanditMatrixSimple.showLoadingDialog('Desasociando artículo del traspaso actual...');
                ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/picking-store/line-request-disassociate', { filters: filtersToGetProducts, productReference: codeScanned }, localStorage.getItem("access_token"), "postLineRequestDisassociate");
              }
              else if(this.itemReferencesProvider.checkCodeValue(codeScanned) == this.itemReferencesProvider.codeValue.PACKAGE){
                ScanditMatrixSimple.showLoadingDialog('Desasociando bulto del traspaso actual...');
                if(listProductsDisassociated.map((pack: any) => pack.uniqueCode).includes(codeScanned)) {
                  ScanditMatrixSimple.hideLoadingDialog();
                  ScanditMatrixSimple.setText(
                    'El bulto escaneado ya se ha desasociado anteriormente.',
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(2000);
                }else{
                  ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/picking-store/order-package-disassociate', { filters: filtersToGetProducts, packageCode: codeScanned }, localStorage.getItem("access_token"), "postOrderPackageDisassociate");
                }
              }
              else {
                ScanditMatrixSimple.setText(
                  `Escanee un producto válido`,
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  18);
                this.hideTextMessage(2000);
              }
            }
          } else {
            if (response.action == 'matrix_simple') {
              if(scanMode != 'carriers') ScanditMatrixSimple.showLoadingDialog('Cargando productos...');
              ScanditMatrixSimple.setTimeout("loadProducts", 1 * 1000, JSON.stringify([listProductsToStorePickings, listProductsProcessed, filtersPicking, listRejectionReasons]));
              if (listProductsToStorePickings.length < 1) {
                if(this.packing){
                  ScanditMatrixSimple.setText(
                    `Escanee el embalaje.`,
                    this.scanditProvider.colorsMessage.info.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(2000);
                  ScanditMatrixSimple.hideLoadingDialog();
                }else {
                  ScanditMatrixSimple.setText(
                    `No hay más productos pendientes de añadir al traspaso.`,
                    this.scanditProvider.colorsMessage.info.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(2000);
                  ScanditMatrixSimple.hideLoadingDialog();
                  ScanditMatrixSimple.setTextPickingStores(true, this.pickingProvider.literalsJailPallet[typePacking].press_scan_packings_to_continue);
                }
              }
            }
            else if (response.action == 'matrix_simple_scan_packings') {
              ScanditMatrixSimple.showButtonPickingStorePacking(false);
              ScanditMatrixSimple.showButtonPickingStoreFinish(true);
              ScanditMatrixSimple.setTextPickingStores(true, `Escanee los embalajes a usar`);
              scanMode = 'carriers';
              this.packingReferences = [];
            }
            else if (response.action == 'matrix_simple_finish_picking') {
              if (this.packingReferences.length == 0) {
                ScanditMatrixSimple.setText(
                  `Añada al menos un embalaje al picking`,
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  18);
                this.hideTextMessage(2000);
              } else {
                ScanditMatrixSimple.showWarning(true, '¿Le han quedado productos sin poder añadir a alguno de los embalajes escaneados? Si es así escanéelos para desasociarlos del traspaso actual.', 'products_out_of_packing', 'Finalizar', 'Escanear productos');
              }
            }
            else if (response.action == 'matrix_simple_finish') {
              if(scanMode == 'carriers'){
                if (this.packingReferences.length == 0) {
                  ScanditMatrixSimple.setText(
                    `Añada al menos un embalaje al picking`,
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    18);
                  this.hideTextMessage(2000);
                } else {
                  ScanditMatrixSimple.showWarning(true, '¿Le han quedado productos sin poder añadir a alguno de los embalajes escaneados? Si es así escanéelos para desasociarlos del traspaso actual.', 'products_out_of_packing', 'Finalizar', 'Escanear productos');
                }
              }else if(scanMode == 'products_disassociate'){
                await this.finishPicking(listProductsDisassociated);
              } else {
                ScanditMatrixSimple.finishPickingStores();
              }
              if(callbackOnFinish){
                callbackOnFinish();
              }
            }
            else if (response.action == 'filters') {
              filtersToGetProducts = {
                models: response.filters.model.map(filter => {
                  return filter.id;
                }),
                brands: response.filters.brand.map(filter => {
                  return filter.id;
                }),
                colors: response.filters.color.map(filter => {
                  return filter.id;
                }),
                sizes: response.filters.size.map(filter => {
                  return filter.name;
                }),
                orderbys: response.filters.sort.map(filter => {
                  return {
                    type: filter.id,
                    order: filter.type_sort.toLowerCase()
                  };
                }),
                toAssociate: packing
              };
              const typesFiltered = response.filters.type.map(filter => {
                return filter.id;
              });
              if(scanMode != 'carriers') ScanditMatrixSimple.showLoadingDialog('Cargando productos...');
              if(typesFiltered.length == 0 || (typesFiltered.length > 0 && typesFiltered.includes(1))){

                ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/picking-store/lines-request/filtered', filtersToGetProducts, localStorage.getItem("access_token"), "postLineRequestFilteredOnFiltersAction");
              }else{
                listProductsToStorePickings = [];
                listProductsProcessed = [];

                ScanditMatrixSimple.hideLoadingDialog();

                if(this.packing){
                  let scannedPackings: {
                    reference: string
                  }[] = [];
                  for(let ref of this.packingReferences){
                    if(!scannedPackings.map(s=>s.reference).includes(ref)){
                      scannedPackings.push({reference: ref});
                    }
                  }
                  ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, listProductsProcessed, null);
                }else{
                  ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, null);
                }
                this.refreshListPickingsStores();
              }
            }
            else if (response.action == 'request_reject') {
              ScanditMatrixSimple.showLoadingDialog('Rechazando artículo del traspaso...');

              let reasonId = response.reasonId;
              let requestReference = response.requestReference;

              ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/picking-store/line-request-reject', {
                filters: filtersToGetProducts,
                reasonRejectionId: reasonId,
                reference: requestReference
              }, localStorage.getItem("access_token"), "postRejectRequest");
            }
            else if (response.action == 'products_out_of_packing') {
              if (response.response) {
                await this.finishPicking(listProductsDisassociated);
              } else {
                ScanditMatrixSimple.setTextPickingStores(true, 'Escanee los productos a desasociar');
                scanMode = 'products_disassociate';
                if(this.packing){
                  ScanditMatrixSimple.sendPickingStoresProducts(listProductsDisassociated, listProductsProcessed, null, true);
                }else{
                  ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, null);
                }
                this.refreshListPickingsStores();
              }
            }
            else if (response.action == 'delete_packing'){
              const packingToDelete: string = response.params;
              for(let i = 0; i < this.packingReferences.length; i++){
                if(this.packingReferences[i] == packingToDelete){
                  this.packingReferences.splice(i, 1);
                  break;
                }
              }
              let scannedPackings: { reference: string }[] = [];
              for(let ref of this.packingReferences){
                if(!scannedPackings.map(s=>s.reference).includes(ref)){
                  scannedPackings.push({reference: ref});
                }
              }
              ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, this.processed, null);
              ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, this.processed, null);
              ScanditMatrixSimple.setText(
                `Embalaje ${packingToDelete} eliminado del traspaso`,
                this.scanditProvider.colorsMessage.info.color,
                this.scanditProvider.colorText.color,
                18);
              this.hideTextMessage(2000);
            }
            else if (response.action == 'back'){
              if(callbackOnBack){
                callbackOnBack();
              }
            }
            else if (response.action == 'request') {

              let responseData = null;
              if (response.data) {
                responseData = JSON.parse(response.data);
              }
              switch (response.requestType) {
                case "postPickingStoreProcess":
                  ScanditMatrixSimple.hideLoadingDialog();
                  if (response.code == 200 || response.code == 201) {

                    const processedRequest: ListItem = responseData.data;
                    //delete processed request from pending requests
                    for(let index = 0; index < listProductsToStorePickings.length; index++){
                      if(listProductsToStorePickings[index].id == processedRequest.id && listProductsToStorePickings[index].shippingMode == processedRequest.shippingMode){
                        processedRequest.model = listProductsToStorePickings[index].model;
                        processedRequest.size = listProductsToStorePickings[index].size;
                        listProductsToStorePickings.splice(index, 1);
                        break;
                      }
                    }
                    //add processed request to processed requests
                    listProductsProcessed.push(processedRequest);

                    if(this.packing){
                      let scannedPackings: {
                        reference: string
                      }[] = [];
                      for(let ref of this.packingReferences){
                        if(!scannedPackings.map(s=>s.reference).includes(ref)){
                          scannedPackings.push({reference: ref});
                        }
                      }
                      ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, listProductsProcessed, null);
                    }else{
                      ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, null);
                    }
                    ScanditMatrixSimple.setText(
                      `Producto ${this.lastCodeScanned} escaneado y añadido al traspaso.`,
                      this.scanditProvider.colorsMessage.info.color,
                      this.scanditProvider.colorText.color,
                      18);
                    this.hideTextMessage(2000);
                    if (listProductsToStorePickings.length < 1) {
                      ScanditMatrixSimple.setTimeout("setNotProductPending", 2 * 1000, JSON.stringify([typePacking]));
                    }
                    this.refreshListPickingsStores();
                  } else {
                    ScanditMatrixSimple.setText(
                      responseData.errors,
                      this.scanditProvider.colorsMessage.error.color,
                      this.scanditProvider.colorText.color,
                      18);
                    this.hideTextMessage(2000);
                    this.loadLineRequestsPending(listProductsToStorePickings, listProductsProcessed, filtersToGetProducts, typePacking);
                  }
                  break;

                case "postLineRequestFiltered":
                  ScanditMatrixSimple.hideLoadingDialog();
                  if (response.code == 200 || response.code == 201) {
                    listProductsToStorePickings = responseData.data.pending;
                    listProductsProcessed = responseData.data.processed;
                    if(this.packing){
                      let scannedPackings: {
                        reference: string
                      }[] = [];
                      for(let ref of this.packingReferences){
                        if(!scannedPackings.map(s=>s.reference).includes(ref)){
                          scannedPackings.push({reference: ref});
                        }
                      }
                      ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, listProductsProcessed, null);
                    }else{
                      ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, null);
                    }
                    if (listProductsToStorePickings.length < 1) {
                      ScanditMatrixSimple.setTimeout("setNotProductPending",  2 * 1000, JSON.stringify([typePacking]));
                    }
                    this.refreshListPickingsStores();
                  }
                  break;

                case "postLineRequestDisassociate":
                  if (response.code == 200 || response.code == 201) {
                    listProductsDisassociated.push(JSON.parse(JSON.stringify(listProductsProcessed.filter(p=>!responseData.data.processed.map(r=>r.id).includes(p.id)))));
                    listProductsDisassociated[listProductsDisassociated.length-1].disassociatedReference = this.lastCodeScanned;
                    let resData: PickingStoreModel.ResponseDataLineRequestsFiltered = responseData.data;
                    listProductsToStorePickings = resData.pending;
                    listProductsProcessed = resData.processed;
                    if(resData && resData.orderPackages && resData.orderPackages.length > 0){
                      for(let pack of resData.orderPackages){
                        listProductsProcessed.push(<any> pack);
                      }
                    }
                    if(this.packing){
                      ScanditMatrixSimple.sendPickingStoresProducts(listProductsDisassociated, listProductsProcessed, null, true);
                    }else{
                      ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, null);
                    }
                    this.refreshListPickingsStores();

                    ScanditMatrixSimple.hideLoadingDialog();
                    ScanditMatrixSimple.setText(
                      'El artículo ha sido desasociado del traspaso actual.',
                      this.scanditProvider.colorsMessage.success.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(2000);
                    break;
                  } else {
                    ScanditMatrixSimple.hideLoadingDialog();
                    ScanditMatrixSimple.setText(
                      responseData.errors,
                      this.scanditProvider.colorsMessage.error.color,
                      this.scanditProvider.colorText.color,
                      18);
                    this.hideTextMessage(2000);
                  }

                case "postOrderPackageDisassociate":
                  listProductsDisassociated.push(JSON.parse(JSON.stringify(listProductsProcessed.filter((pack: any) => pack.uniqueCode == this.lastCodeScanned)[0])));
                  listProductsProcessed = responseData.data.processed.concat(<any> responseData.data.orderPackages).filter(p => !listProductsDisassociated.map(d => d.id).includes(p.id));
                  ScanditMatrixSimple.sendPickingStoresProducts(listProductsDisassociated, listProductsProcessed, null, true);

                  this.refreshListPickingsStores();
                  ScanditMatrixSimple.hideLoadingDialog();
                  ScanditMatrixSimple.setText(
                    'El bulto ha sido desasociado del traspaso actual.',
                    this.scanditProvider.colorsMessage.success.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(2000);
                  break;

                case "postPackings":
                  ScanditMatrixSimple.hideLoadingDialog();
                  if (response.code == 200 || response.code == 201) {
                    ScanditMatrixSimple.finishPickingStores();
                    this.refreshListPickingsStores();
                    await this.intermediaryService.presentToastSuccess('Finalizada correctamente la asociación de pares a embalajes', TimesToastType.DURATION_SUCCESS_TOAST_4550, PositionsToast.BOTTOM);
                  } else {
                    ScanditMatrixSimple.setText(
                      responseData.errors,
                      this.scanditProvider.colorsMessage.error.color,
                      this.scanditProvider.colorText.color,
                      18);
                    this.hideTextMessage(2000);
                    this.packingReferences = [];
                    let scannedPackings: {
                      reference: string
                    }[] = [];
                    for (let ref of this.packingReferences) {
                      if (!scannedPackings.map(s => s.reference).includes(ref)) {
                        scannedPackings.push({ reference: ref });
                      }
                    }
                    ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, this.processed, null);
                    ScanditMatrixSimple.setTextPickingStores(true, `Escanee de nuevo los embalajes a usar`);
                  }
                  break;

                case "postLineRequestFilteredOnFiltersAction":
                  ScanditMatrixSimple.hideLoadingDialog();
                  if (response.code == 200) {
                    listProductsToStorePickings = responseData.data.pending;
                    listProductsProcessed = responseData.data.processed;
                    if(this.packing){
                      let scannedPackings: {
                        reference: string
                      }[] = [];
                      for(let ref of this.packingReferences){
                        if(!scannedPackings.map(s=>s.reference).includes(ref)){
                          scannedPackings.push({reference: ref});
                        }
                      }
                      ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, listProductsProcessed, null);
                    }else{
                      ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, null);
                    }
                    this.refreshListPickingsStores();
                  }
                  break;

                case "postRejectRequest":
                  let respData: PickingStoreModel.RejectRequest = responseData.data;
                  listProductsToStorePickings = respData.linesRequestFiltered.pending;
                  listProductsProcessed = respData.linesRequestFiltered.processed;
                  if(this.packing){
                    let scannedPackings: {
                      reference: string
                    }[] = [];
                    for(let ref of this.packingReferences){
                      if(!scannedPackings.map(s=>s.reference).includes(ref)){
                        scannedPackings.push({reference: ref});
                      }
                    }
                    ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, listProductsProcessed, null);
                  }else{
                    ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, null);
                  }
                  this.refreshListPickingsStores();

                  ScanditMatrixSimple.hideLoadingDialog();
                  ScanditMatrixSimple.setText(
                    'El artículo ha sido rechazado del traspaso actual.',
                    this.scanditProvider.colorsMessage.success.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(2000);
                  ScanditMatrixSimple.hideInfoProductDialog();
                  break;
              }
            }
          }
        }
      }, title ? title : 'Picking', this.scanditProvider.colorsHeader.background.color, this.scanditProvider.colorsHeader.color.color, textPickingStoresInit, environment.urlBase);
  }

  private loadLineRequestsPending(listProductsToStorePickings: ListItem[], listProductsProcessed: ListItem[], filtersToGetProducts: PickingStoreModel.ParamsFiltered, typePacking: number) {
    ScanditMatrixSimple.showLoadingDialog('Consultando productos restantes...');
    ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/picking-store/lines-request/filtered', filtersToGetProducts, localStorage.getItem("access_token"), "postLineRequestFiltered");
  }

  private async finishPicking(listProductsDisassociated: ListItem[]) {
    if (this.packingReferences.length === 0) {
      ScanditMatrixSimple.setText(
        `Añada al menos un embalaje al picking`,
        this.scanditProvider.colorsMessage.error.color,
        this.scanditProvider.colorText.color,
        18);
      this.hideTextMessage(2000);
    } else {
      ScanditMatrixSimple.showLoadingDialog('Finalizando proceso...');
      ScanditMatrixSimple.setText(
        'Finalizando proceso...',
        this.scanditProvider.colorsMessage.info.color,
        this.scanditProvider.colorText.color,
        18);
      this.hideTextMessage(2000);
      ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/picking-store/packing', {
        packingReferences: this.packingReferences,
        packagesDisassociatedIds: listProductsDisassociated.filter((p: any) => p.uniqueCode).map(p => p.id),
        external: this.pickingProvider.subgroup && this.pickingProvider.subgroup == 'external',
        store: this.pickingProvider.subgroup && this.pickingProvider.subgroup == 'store'
      }, localStorage.getItem("access_token"), "postPackings");
    }
  }

  private refreshListPickingsStores() {
    this.events.publish('picking-stores:refresh');
  }

  private hideTextMessage(delay: number) {
    ScanditMatrixSimple.setTimeout("hideText", delay, "");
  }

  private executeAction(action: string, paramsString: string){
    let params = [];
    try{
      params = JSON.parse(paramsString);
    } catch (e) {

    }
    switch (action){
      case 'lastCodeScannedStart':
        this.lastCodeScanned = 'start';
        break;
      case 'setNotProductPending':
        if(this.packing){
          ScanditMatrixSimple.setText(
            `Escanee el embalaje.`,
            this.scanditProvider.colorsMessage.info.color,
            this.scanditProvider.colorText.color,
            16);
          this.hideTextMessage(2000);
        }else{
          let typePacking = params[0];
          ScanditMatrixSimple.setText(
            `No hay más productos pendientes de añadir al traspaso.`,
            this.scanditProvider.colorsMessage.info.color,
            this.scanditProvider.colorText.color,
            16);
          this.hideTextMessage(2000);
          ScanditMatrixSimple.setTextPickingStores(true, this.pickingProvider.literalsJailPallet[typePacking].press_scan_packings_to_continue);
        }
        break;
      case 'scannedPacking':
        let codeScanned = params[0];
        ScanditMatrixSimple.hideLoadingDialog();
        if(!this.packingReferences.includes(codeScanned)) this.packingReferences.push(codeScanned);
        let scannedPackings: {
          reference: string
        }[] = [];
        for(let ref of this.packingReferences){
          if(!scannedPackings.map(s=>s.reference).includes(ref)){
            scannedPackings.push({reference: ref});
          }
        }
        ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, this.processed, null);
        ScanditMatrixSimple.setText(
          `Escaneado embalaje ${codeScanned} para añadir al traspaso`,
          this.scanditProvider.colorsMessage.info.color,
          this.scanditProvider.colorText.color,
          18);
        this.hideTextMessage(2000);
        break;
      case 'loadProducts':
        let listProductsToStorePickings = params[0];
        let listProductsProcessed = params[1];
        let filtersPicking = params[2];
        let listRejectionReasons = params[3];
        if(this.packing){
          let scannedPackings: {
            reference: string
          }[] = [];
          for(let ref of this.packingReferences){
            if(!scannedPackings.map(s=>s.reference).includes(ref)){
              scannedPackings.push({reference: ref});
            }
          }
          ScanditMatrixSimple.sendPickingStoresProducts(scannedPackings, listProductsProcessed, filtersPicking);
        }else{
          ScanditMatrixSimple.sendPickingStoresProducts(listProductsToStorePickings, listProductsProcessed, filtersPicking);
        }
        ScanditMatrixSimple.sendPickingStoresRejectionReasons(listRejectionReasons);
        break;
      case 'hideText':
        ScanditMatrixSimple.showText(false);
        break;
    }
  }
}
