import {Injectable} from '@angular/core';
import {InventoryService} from "../../endpoint/inventory/inventory.service";
import {WarehouseService} from "../../endpoint/warehouse/warehouse.service";
import {AuthenticationService} from "../../endpoint/authentication/authentication.service";
import {AlertController, Events, ModalController} from "@ionic/angular";
import {ScanditProvider} from "../../../providers/scandit/scandit.provider";
import {ReceptionService} from "../../endpoint/process/reception/reception.service";
import {environment, ProductModel, WarehouseModel} from "@suite/services";
import {ReceptionModel} from "../../../models/endpoints/Reception";
import {ReceptionProvider} from "../../../providers/reception/reception.provider";
import {Router} from "@angular/router";
import {environment as al_environment} from "../../../../../../apps/al/src/environments/environment";
import {ItemReferencesProvider} from "../../../providers/item-references/item-references.provider";
import {PrinterService} from "../../printer/printer.service";
import {LocalStorageProvider} from "../../../providers/local-storage/local-storage.provider";
import {PickingNewProductsService} from "../../endpoint/picking-new-products/picking-new-products.service";
import {PickingNewProductsModel} from "../../../models/endpoints/PickingNewProducts";
import {AfterPackingReceptionComponent} from "../../../../../modules/src/components/after-packing-reception/after-packing-reception.component";

declare let ScanditMatrixSimple;

@Injectable({
  providedIn: 'root'
})
export class ReceptionScanditService {

  private timeoutHideText;
  private scannerPaused: boolean = false;
  private lastCodeScanned: string;
  private typeReception: number = 1;

  private isStoreUser: boolean = false;
  private storeUserObj: WarehouseModel.Warehouse = null;

  private lastResData;

  private readonly timeMillisToResetScannedCode: number = 2000;

  private refenceProductToPrint: string = null;
  private requestedProductId: number = null;
  private oPOrderPackage = false;
  private lastPackingReference: string = null;
  private supportCode = null;
  constructor(
    private router: Router,
    private alertController: AlertController,
    private auth: AuthenticationService,
    private events: Events,
    private inventoryService: InventoryService,
    private warehouseService: WarehouseService,
    private authenticationService: AuthenticationService,
    private receptionService: ReceptionService,
    private printerService: PrinterService,
    private pickingNewProductsService: PickingNewProductsService,
    private scanditProvider: ScanditProvider,
    private receptionProvider: ReceptionProvider,
    private localStorageProvider: LocalStorageProvider,
    private itemReferencesProvider: ItemReferencesProvider,
    private modalController: ModalController
) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
  }

  async reception(typeReception: number) {
    this.isStoreUser = await this.authenticationService.isStoreUser();
    if (this.isStoreUser) {
      this.storeUserObj = await this.authenticationService.getStoreCurrentUser();
    }

    this.lastCodeScanned = 'start';
    this.receptionProvider.resumeProcessStarted = false;
    this.typeReception = typeReception;

    ScanditMatrixSimple.init(async (response) => {
      let code = '';

      if (response) {
        if (response.actionIonic && response.actionIonic == 'lastCodeScannedStart') {
          this.lastCodeScanned = 'start';
        }
        if (response.barcode && response.barcode.data) {
          code = response.barcode.data;

          if (!this.scannerPaused && code != this.lastCodeScanned) {
            this.lastCodeScanned = code;

            ScanditMatrixSimple.setTimeout("lastCodeScannedStart", this.timeMillisToResetScannedCode, "");

            this.scannerPaused = true;
            if(!this.itemReferencesProvider.checkSpecificCodeValue(code, this.itemReferencesProvider.codeValue.PACKAGE )){
              switch (this.itemReferencesProvider.checkCodeValue(code)) {
                case this.itemReferencesProvider.codeValue.PACKING:
                  this.processPackingScanned(code);
                  break;
                case this.itemReferencesProvider.codeValue.PRODUCT:
                  ScanditMatrixSimple.showFixedTextBottom(false, '');
                  this.processProductScanned(code);
                  break;
                default:
                  ScanditMatrixSimple.setText(
                    'Necesita escanear un código de Jaula o Pallet para hacer una recepción.',
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    18);
                  this.hideTextMessage(1500);
                  setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
              }
            }else{
              ScanditMatrixSimple.showFixedTextBottom(false, '');
              this.processProductScanned(code);
            }
          }
        } else if (response.action) {
          switch (response.action) {
            case 'matrix_simple':
              if (this.typeReception == 2) {
                ScanditMatrixSimple.showButtonFinishReception(true);
              } else {
                ScanditMatrixSimple.showButtonFinishReception(false);
              }
              break;
            case 'finish_reception':
              // Request user scan packing to set as empty
              ScanditMatrixSimple.showFixedTextBottom(true, 'Escanea la jaula que desea marcar como vacía.');
              break;
            case 'force_scanning':
              if (response.force) {
                // Force product scanning
                this.setProductAsReceived(response.barcode, true);
              } else if (response.avoid) {
                this.scannerPaused = false;
                // Avoid product and don't make anything
              }
              break;
            case 'reception_incomplete':
              if (response.response) {
                // Continue scanning products and don't make anything
                this.scannerPaused = false;
              } else {
                // No more products in packing, create incidence in backend
                this.incidenceNotReceived(this.receptionProvider.referencePacking);
              }
              break;
            case 'order_codes':
              if (response.response) {
                ScanditMatrixSimple.finish();
                this.router.navigate(['/package-received']);
              }
              break;
            case 'wrong_code_msg':
              this.scannerPaused = false;
              break;
            case 'new_product_expo':
              this.scannerPaused = false;
              if (response.response) {
                this.printNewProductPriceTag();
              }
              break;
            case 'requested_attended':
              this.scannerPaused = false;
              if (response.response) {
                this.attendProductReceived(this.requestedProductId);
              } else {
                this.requestedProductId = null;
              }
              break;
            case 'action_select':
              if(response.response == 'products'){
                ScanditMatrixSimple.finish();
                const routeFragments = ['print', 'product', 'received', 'scandit', this.lastResData.hasNewProducts];
                if (this.lastResData.hasRequestedProducts) {
                  routeFragments.push(true);
                }
                this.router.navigate(routeFragments);
              }else{
                if(response.response == 'packages'){
                  ScanditMatrixSimple.finish();
                  this.router.navigate(['/package-received']);
                }
              }
              break;
            case 'request':
              let responseData = null;
              switch (response.requestType) {
                case "postReceiveProduct":
                  ScanditMatrixSimple.hideLoadingDialog();
                  if (response.code === 201) {
                    responseData = JSON.parse(response.data);
                    ScanditMatrixSimple.setText(
                      `Producto recepcionado.`,
                      this.scanditProvider.colorsMessage.success.color,
                      this.scanditProvider.colorText.color,
                      18);
                    this.hideTextMessage(1500);

                    this.localStorageProvider.get('hideAlerts').then(hide=> {
                      let hideAlerts = JSON.parse(String(hide));

                      if(responseData.data.orderCode){
                        ScanditMatrixSimple.showWarning(true, 'Recibido pedido ' + responseData.data.orderCode + '. ¿Quiere ir a la sección de pedidos recibidos para procesarlos?', 'order_codes', 'Sí', 'No');
                      } else if (responseData.data.hasRequestedProducts) {
                        this.requestedProductId = responseData.data.productRequestedId;
                        ScanditMatrixSimple.showWarning(true, 'El producto escaneado corresponde a un producto solicitado a otra tienda.', 'requested_attended', 'Atendido', 'No atender');
                      } else if (responseData.data.hasNewProducts && !hideAlerts) {
                        ScanditMatrixSimple.showWarning(true, `El producto escaneado es nuevo en la tienda. ¿Quiere imprimir su código de exposición ahora?`, 'new_product_expo', 'Sí', 'No');
                      } else if(responseData.data.isNoOnline) {
                        ScanditMatrixSimple.showWarning(true, 'El producto escaneado ['+this.refenceProductToPrint+'] no es apto para la venta online.', 'no_online', 'Aceptar', '');
                      }
                    });
                    this.scannerPaused = false;
                  } else if (response.code === 428) {
                    this.scannerPaused = true;
                    ScanditMatrixSimple.showWarningToForce(true, this.refenceProductToPrint);
                  } else {
                    responseData = JSON.parse(response.data);
                    ScanditMatrixSimple.setText(
                      responseData.errors,
                      this.scanditProvider.colorsMessage.error.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(1500);
                    this.scannerPaused = false;
                  }
                  break;

                case "postUpdateStock":
                  break;

                case "postReceive":
                  if (response.code === 200 || response.code === 201) {
                    responseData = JSON.parse(response.data);

                    // Update the stock of all the products form this packing in this warehouse.
                    ScanditMatrixSimple.request("GET", environment.apiBase + '/processes/receive-store/check/'+ this.lastPackingReference +'/products', {}, localStorage.getItem("access_token"), "getCheckProductsPacking");
                    if (this.typeReception === 1) {
                      ScanditMatrixSimple.showFixedTextBottom(false, '');
                      this.receptionProvider.referencePacking = null;
                      this.receptionProvider.typePacking = 0;
                      this.receptionProvider.processStarted = false;

                      if (responseData.data.quantity > 0) {
                        const count = {
                          packingReference: this.lastPackingReference,
                          total: responseData.data.quantity,
                          packages: 0,
                          receivedProducts: 0,
                          newProducts: 0,
                          requestedProducts: 0,
                          noOnlineProducts: 0
                        };

                        count.packages = (responseData.data.orderCodes && responseData.data.orderCodes.length > 0) ? responseData.data.orderCodes.length : 0;
                        count.newProducts = responseData.data.newQuantity;
                        count.requestedProducts = responseData.data.requestedQuantity;
                        count.noOnlineProducts = responseData.data.noOnlineProducts;
                        count.receivedProducts = responseData.data.quantity - count.packages - count.newProducts - count.requestedProducts;
                        count.total = count.packages + count.newProducts +  count.requestedProducts + count.receivedProducts;

                        ScanditMatrixSimple.hideLoadingDialog();
                        setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
                        ScanditMatrixSimple.finish();

                        this.modalController.create({
                          component: AfterPackingReceptionComponent,
                          componentProps: { count: count, receptionScanditService: this }
                        }).then(async modal => {
                          await modal.present();
                        });
                      } else {
                        ScanditMatrixSimple.hideLoadingDialog();
                        setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
                      }
                    } else {
                      ScanditMatrixSimple.hideLoadingDialog();
                      setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
                    }
                  } else {
                    responseData = JSON.parse(response.data);
                    ScanditMatrixSimple.hideLoadingDialog();
                    ScanditMatrixSimple.setText(
                      responseData.errors,
                      this.scanditProvider.colorsMessage.error.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(2000);
                    setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
                  }

                  break;

                case "getCheckProductsPacking":
                  break;

                case "getCheckPacking":
                  ScanditMatrixSimple.hideLoadingDialog();
                  if (response.code === 200) {
                    ScanditMatrixSimple.setText(
                      'Embalaje ' + this.receptionProvider.referencePacking + ' vaciado',
                      this.scanditProvider.colorsMessage.success.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(1500);
                    setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
                    ScanditMatrixSimple.showFixedTextBottom(false, '');
                    this.localStorageProvider.get('hideAlerts').then(hide=> {
                      let hideAlerts = JSON.parse(String(hide));
                      if (hideAlerts) {
                        ScanditMatrixSimple.finish();
                        this.router.navigate(['new-products']);
                      }
                    });
                  } else if (response.code === 428) {
                    // Process custom status-code response to check packing
                    ScanditMatrixSimple.showWarning(true, '¿Quedan productos sin recepcionar en ' + this.receptionProvider.referencePacking + '?', 'reception_incomplete', 'Sí, continuar', 'No, embalaje vacío');
                  } else {
                    this.lastCodeScanned = 'start';
                    responseData = JSON.parse(response.data);
                    ScanditMatrixSimple.setText(
                      responseData.errors,
                      this.scanditProvider.colorsMessage.error.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(2000);
                    setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
                  }
                  break;

                case "getNotReceivedProducts":
                  ScanditMatrixSimple.hideLoadingDialog();
                  responseData = JSON.parse(response.data);
                  if (response.code == 201) {
                    ScanditMatrixSimple.setText(
                      `${this.receptionProvider.literalsJailPallet.packing_emptied}`,
                      this.scanditProvider.colorsMessage.success.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(1500);
                    setTimeout(() => this.scannerPaused = false, 1.5 * 1000);
                  } else {
                    this.scannerPaused = false;
                    console.error('Error::Subscribe::Set products as not received after empty packing::', responseData);
                  }
                  break;

                case "promisePutAttendReceivedProductsRequested":
                  if (response.code == 201) {
                    ScanditMatrixSimple.setText(
                      'Producto atendido correctamente',
                      this.scanditProvider.colorsMessage.success.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(1500);
                  } else {
                    ScanditMatrixSimple.setText(
                      'Ha ocurrido un error al intentar marcar como atendido el producto.',
                      this.scanditProvider.colorsMessage.error.color,
                      this.scanditProvider.colorText.color,
                      16);
                    this.hideTextMessage(4000);
                  }
                  break;

                case "postPricesByProductsReferences":
                  if (response.data) {
                    responseData = JSON.parse(response.data);
                    let dataToPrint = this.printerService.processProductToPrintTagPrice(responseData.data);
                    console.debug("PRINT::printTagPrices 3 [" + new Date().toJSON() + "]", responseData.data);
                    let printRes = await this.printerService.toPrintFromString(dataToPrint.valuePrint);
                    if (printRes) {
                      ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/printReferences", { hashes: dataToPrint.options.map(option => option.price.hash) }, localStorage.getItem("access_token"), "printNotify");
                    }

                  }
                  break;

                case "printNotify":
                  break;
              }
              break;
            default:
              break;
          }
        }
      }
    }, 'Recepción', this.scanditProvider.colorsHeader.background.color, this.scanditProvider.colorsHeader.color.color);
  }

  private processPackingScanned(code: string) {
    if (this.itemReferencesProvider.checkSpecificCodeValue(code, this.itemReferencesProvider.codeValue.JAIL)) {
      this.receptionProvider.typePacking = 1;
    } else if (this.itemReferencesProvider.checkSpecificCodeValue(code, this.itemReferencesProvider.codeValue.PALLET)) {
      this.receptionProvider.typePacking = 2;
    } else if (this.itemReferencesProvider.checkSpecificCodeValue(code, this.itemReferencesProvider.codeValue.PACKAGE)) {
      this.receptionProvider.typePacking = 3;
    }

    if (this.typeReception == 1) {
      ScanditMatrixSimple.showLoadingDialog('Recepcionando embalaje '+code+' ...');
      setTimeout(()=> {
        this.setPackingAsReceived(code);
      }, 500);
    } else if (this.typeReception == 2) {
      ScanditMatrixSimple.showFixedTextBottom(false, '');
      this.receptionProvider.referencePacking = code;
      ScanditMatrixSimple.showLoadingDialog('Comprobando embalaje a recepcionar...');
      ScanditMatrixSimple.request("GET", environment.apiBase + '/processes/receive-store/check/' + code, {}, localStorage.getItem("access_token"), "getCheckPacking");
  }

  }

  private processProductScanned(code: string) {
    if (this.typeReception == 1) {
      // Warning message notice that this method is only to scan jail or pallet, not products
      ScanditMatrixSimple.showWarning(true, `Este método es para escanear únicamente jaulas o pallets, no productos.`, 'wrong_code_msg');
      this.scannerPaused = false;
    } else {
      this.setProductAsReceived(code);
    }
  }

  // Reception of packing in MGA and all products associated
  private setPackingAsReceived(packingReference: string) {
    this.lastPackingReference = packingReference;
    ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/receive-store', { packingReference: packingReference }, localStorage.getItem("access_token"), "postReceive");
  }

  // Reception of product in MGA
  private setProductAsReceived(referenceProduct: string, force: boolean = false) {
    let params: ReceptionModel.ReceptionProduct = {
      productReference: referenceProduct
    };

    if (force) {
      params.force = force;
    }

    this.refenceProductToPrint = referenceProduct;

    ScanditMatrixSimple.showLoadingDialog('Recepcionando producto...');
    ScanditMatrixSimple.request("POST", environment.apiBase + '/processes/receive-store/products', params, localStorage.getItem("access_token"), "postReceiveProduct");
  }

  private incidenceNotReceived(packingReference: string) {
    ScanditMatrixSimple.showLoadingDialog('Notificando productos no recepcionados...');
    ScanditMatrixSimple.request("GET", environment.apiBase + '/processes/receive-store/notreceived/' + packingReference, {}, localStorage.getItem("access_token"), "getNotReceivedProducts");
  }

  private printNewProductPriceTag() {
    // this.printerService.printTagPrices([this.refenceProductToPrint]);
    console.debug("PRINT::printTagPrices 1 [" + new Date().toJSON() + "]", [this.refenceProductToPrint]);
    console.debug("PRINT::printTagPrices 2 [" + new Date().toJSON() + "]", [this.refenceProductToPrint]);
    ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/references-products", { references: [this.refenceProductToPrint] }, localStorage.getItem("access_token"), "postPricesByProductsReferences");
  }

  private attendProductReceived(requestedProductId: number) {
    if (requestedProductId) {
      ScanditMatrixSimple.request("PUT", environment.apiBase + '/picking-new-products/products/received/requested/attend', {receivedProductsRequestedIds: [requestedProductId]}, localStorage.getItem("access_token"), "promisePutAttendReceivedProductsRequested");
    }
  }

  private hideTextMessage(delay: number) {
    if (this.timeoutHideText) {
      clearTimeout(this.timeoutHideText);
    }
    this.timeoutHideText = setTimeout(() => {
      ScanditMatrixSimple.showText(false);
    }, delay);
  }
}
