import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, ExpeditionService, IntermediaryService, LabelsService} from "@suite/services";
import { ToolbarProvider } from "../../../services/src/providers/toolbar/toolbar.provider";
import { ActivatedRoute } from '@angular/router';
import { FileTransfer} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { environment } from '../../../services/src/environments/environment';
import { Downloader,DownloadRequest,NotificationVisibility } from '@ionic-native/downloader/ngx';
import {LabelModel} from "../../../services/src/models/endpoints/Label";
import {PrinterServiceService} from "../../../services/src/lib/endpoint/printer-service/printer-service.service";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {PrinterServiceModel} from "../../../services/src/models/endpoints/PrinterService";
import {config} from "../../../services/src/config/config";
import {DownloaderService} from "../../../services/src/lib/downloader/downloader.service";
import {KeyboardService} from "../../../services/src/lib/keyboard/keyboard.service";
import {AudioProvider} from "../../../services/src/providers/audio-provider/audio-provider.provider";
import {LoadingMessageComponent} from "../components/loading-message/loading-message.component";
import {environment as al_environment} from "../../../../apps/al/src/environments/environment";
import {ExpeditionModel} from "../../../services/src/models/endpoints/Expedition";
import ResponseCheckPackageForExpedition = ExpeditionModel.ResponseCheckPackageForExpedition;
import IExpeditionInfo = ExpeditionModel.IExpeditionInfo;
import IExpeditionInfoPackageProduct = ExpeditionModel.IExpeditionInfoPackageProduct;
import IExpeditionInfoPackage = ExpeditionModel.IExpeditionInfoPackage;
import ResponseCheckProductsForExpedition = ExpeditionModel.ResponseCheckProductsForExpedition;
import IExpeditionHiringInfo = ExpeditionModel.IExpeditionHiringInfo;

declare let ScanditMatrixSimple;

@Component({
  selector: 'order-preparation',
  templateUrl: './order-preparation.component.html',
  styleUrls: ['./order-preparation.component.scss'],
})
export class OrderPreparationComponent implements OnInit {

  @ViewChild(LoadingMessageComponent) loadingMessageComponent: LoadingMessageComponent;

  readMode: number;
  private lastReadMode: number;
  dataToRead: string;
  isScannerBlocked: boolean = false;
  private lastCodeScanned;
  private lastCodeReadedProcessed: string = null;

  readonly SCANNER_READ_MODE: number = 0;
  readonly LASER_READ_MODE: number = 1;
  readonly KEYBOARD_READ_MODE: number = 2;

  expeditionToRead: IExpeditionInfo;
  allProducts: IExpeditionInfoPackageProduct[];
  totalProducts: number;
  readedProducts: string[];
  readedPackages: IExpeditionInfoPackage[];
  hiringInfoProcessed: IExpeditionHiringInfo = null;

  private readonly PRODUCT_BARCODE_REGEX: RegExp = /([0]){2}([0-9]){6}([0-9]){2}([0-9]){3}([0-9]){5}$/;
  private timeoutStarted = null;
  private timeoutHideText = null;
  private readonly timeMillisToResetScannedCode: number = 1000;

  private readonly BACKGROUND_COLOR_ERROR: string = '#e8413e';
  private readonly BACKGROUND_COLOR_INFO: string = '#15789e';
  private readonly BACKGROUND_COLOR_SUCCESS: string = '#2F9E5A';
  private readonly TEXT_COLOR: string = '#FFFFFF';
  private readonly HEADER_BACKGROUND: string = '#222428';
  private readonly HEADER_COLOR: string = '#FFFFFF';

  StatusPrint: boolean = false;
  initPage: boolean = true;
  PrintError: boolean = false;
  showButtonReadNewExpedition:boolean = false;
  numPackages:number = 0;
  expeditionId:number = 0;
  expeditionIdForCheckShippingLabels:number =0;
  viewInput:boolean = false;
  id=0;
  camera: boolean = false;

  private isInternal:boolean = false;
  private orderId:number;
  private loadingDownloadExpeditionIdShown :number = 0;
  private incidenceResolved:boolean = false;

  public expeditionExtraDocs: LabelModel.IExpeditionDocToPrint[] = [];

  public isCheckingAnExpeditionToPrint: boolean = false;

  constructor(
    private labelsService: LabelsService,
    private router: Router,
    private intermediaryService: IntermediaryService,
    private expeditionService: ExpeditionService,
    private toolbarProvider: ToolbarProvider,
    private routeParams: ActivatedRoute,
    private transfer: FileTransfer,
    private file:File,
    private downloader:Downloader,
    private printerServiceService: PrinterServiceService,
    private downloaderService: DownloaderService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private keyboardService: KeyboardService,
    private audioProvider: AudioProvider,
    private detectorRef: ChangeDetectorRef
  ) {
    this.isCheckingAnExpeditionToPrint = false;
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
  }

  async ngOnInit() {

    this.expeditionToRead = null;
    this.allProducts = [];
    this.totalProducts = 0;
    this.readedProducts = [];
    this.readedPackages = [];
    this.isScannerBlocked = false;

    this.isCheckingAnExpeditionToPrint = false;
    this.toolbarProvider.currentPage.next("Generar etiquetas de envio");

    this.readMode = await this.authenticationService.isStoreUser() ? this.SCANNER_READ_MODE : this.LASER_READ_MODE;
    this.lastReadMode = this.readMode;

    if(this.activatedRoute && this.activatedRoute.snapshot && this.activatedRoute.snapshot.paramMap && this.activatedRoute.snapshot.paramMap.get('incidenceResolved') && (this.routeParams.snapshot.params.id != undefined)){
      this.incidenceResolved = true;
      this.expeditionId = this.routeParams.snapshot.params.id;
      await this.loadProductsByExpeditionId(this.expeditionId);
    } else {
      this.incidenceResolved = false;
      if (this.readMode === this.SCANNER_READ_MODE) {
        await this.startScanner();
      }
    }
  }

  public changeReadModeFromLaserOrKeyboardToScanner() {
    this.isScannerBlocked = false;
    this.lastReadMode = this.SCANNER_READ_MODE;
    this.readMode = this.SCANNER_READ_MODE;
    this.startScanner();
  }

  private changeReadModeFromScannerToLaserOrKeyboard(showKeyboard: boolean) {
    this.isScannerBlocked = false;
    this.lastReadMode = this.LASER_READ_MODE;
    this.readMode = this.LASER_READ_MODE;
    this.focusToInput(false, 'ok', 2000);
    if(showKeyboard){
      this.cleanViews();
      this.initPage = true;
      this.keyboardService.setInputFocused('input-op');
      this.keyboardService.eneabled();
    } else {
      this.cleanAll();
      this.cleanViews();
      this.showInitPage();
      this.keyboardService.setInputFocused('input-op');
      this.keyboardService.disabled();
    }
		this.detectorRef.detectChanges();
  }

  private changeReadModeFromLaserToKeyboard() {
    this.isScannerBlocked = false;
    this.lastReadMode = this.readMode;
    this.readMode = this.KEYBOARD_READ_MODE;
  }

  private changeReadModeFromKeyboardToLaser() {
    this.isScannerBlocked = false;
    this.lastReadMode = this.readMode;
    this.readMode = this.LASER_READ_MODE;
  }

  private checkIfProcessIsOver() {
    return this.readedPackages.length === this.expeditionToRead.packages.length && this.readedProducts.length === this.totalProducts;
  }

  public async keyUpInput(event?, prova: boolean = false) {
    let writtenData = (this.dataToRead || "").trim();

    if ((event.keyCode === 13 || prova && writtenData) && !this.isScannerBlocked) {
      await this.processCodeReadedToExpedition(writtenData, false);

    } else if (event.keyCode === 13 && this.isScannerBlocked) {
      this.focusToInput();
    }
  }

  private async processCodeReadedToExpedition(writtenData: string, processWithScandit: boolean) {
    this.isScannerBlocked = true;
    if(!processWithScandit) document.getElementById('input-op').blur();
    if (writtenData === this.lastCodeScanned) {
      this.isScannerBlocked = false;
      if(!processWithScandit) this.focusToInput();
      return;
    }
    this.lastCodeScanned = writtenData;

    if(!processWithScandit){
      if (this.timeoutStarted) {
        clearTimeout(this.timeoutStarted);
      }
      this.timeoutStarted = setTimeout(() => {
        this.lastCodeScanned = 'start';
      }, this.timeMillisToResetScannedCode);
    } else {
      ScanditMatrixSimple.setTimeout("lastCodeScannedStart", this.timeMillisToResetScannedCode, "");
    }

    if (!this.PRODUCT_BARCODE_REGEX.test(writtenData)) {
      this.lastCodeReadedProcessed = '';
      await this.processFinishError({
        focusInput: {
          playSound: true
        },
        toast: {
          position: PositionsToast.BOTTOM,
          message: `El código [${writtenData}] no es una referencia de producto válida.`
        }
      }, processWithScandit);
      this.isScannerBlocked = false;
      this.dataToRead = '';
      return;
    }
    this.lastCodeReadedProcessed = writtenData;
    if (this.expeditionToRead) {
      if (this.allProducts.find(p => p.barcode == writtenData)) {
        if (this.isProductReaded(writtenData)) {
          await this.processFinishError({
            focusInput: {
              playSound: true
            },
            toast: {
              position: PositionsToast.BOTTOM,
              message: `El producto [${writtenData}] ya ha sido procesado`
            }
          }, processWithScandit);
          this.isScannerBlocked = false;
          this.dataToRead = '';
          this.focusToInput();
          return;
        } else {
          let msgLoading = `Procesando ${writtenData || ''}`;
          if(processWithScandit){
            ScanditMatrixSimple.showLoadingDialog(msgLoading);
          } else {
            this.loadingMessageComponent.show(true, msgLoading);
          }
          await this.readProductWithExpedition(writtenData, processWithScandit);
        }
      } else {
        const deliveryRequestExternalId = this.expeditionToRead.deliveryRequestExternalId;
        this.isScannerBlocked = false;
        this.dataToRead = '';
        this.close();
        this.cleanAll();
        this.initPage = true;
        this.focusToInput();
        if(processWithScandit){
          this.setScannerExpeditionInfo();
        }
        await this.processFinishError({
          focusInput: {
            playSound: true
          },
          toast: {
            position: PositionsToast.BOTTOM,
            message: `El código del producto escaneado [${writtenData}] no está asociado a la expedición del pedido [${deliveryRequestExternalId}] que está procesando. \n\nDebe comenzar la lectura de productos nuevamente para asegurarse que se embalan los productos correctos.`,
            duration: TimesToastType.DURATION_ERROR_TOAST_LONG
          }
        }, processWithScandit);
        return;
      }
    } else {
      let msgLoading = `Procesando ${writtenData || ''}`;
      if(processWithScandit){
        ScanditMatrixSimple.showLoadingDialog(msgLoading);
      } else {
        this.loadingMessageComponent.show(true, msgLoading);
      }
      this.readFirstProduct(writtenData, processWithScandit);
    }
  }

  private readFirstProduct(writtenData: string, processWithScandit: boolean) {
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `readFirstProduct`, {writtenData, processWithScandit})
    if(!processWithScandit){
      this.expeditionService.getExpeditionByFirstProduct(writtenData).subscribe(async (response: ResponseCheckPackageForExpedition) => {
        await this.processResponseGetExpeditionByFirstProduct(response, writtenData, processWithScandit);
      }, async (error) => {
        await this.processErrorResponseGetExpeditionByFirstProduct(error, processWithScandit);
      });
    } else {
      ScanditMatrixSimple.request("GET", `${environment.apiBase}/opl-expedition/sync-expedition/${writtenData}`, {}, localStorage.getItem("access_token"), "getExpeditionByFirstProduct");
    }
  }

  private async processErrorResponseGetExpeditionByFirstProduct(error, processWithScandit: boolean) {
    if(processWithScandit){
      ScanditMatrixSimple.hideLoadingDialog();
    } else {
      this.loadingMessageComponent.show(false);
    }
    this.dataToRead = '';
    this.isScannerBlocked = false;
    await this.processFinishError({
      focusInput: {
        playSound: true
      },
      toast: {
        position: PositionsToast.BOTTOM,
        message: error && error.error && error.error.errors && typeof error.error.errors == 'string' ? error.error.errors : error && error.errors && typeof error.errors == 'string' ? error.errors : 'Ha ocurrido un error al intentar obtener los datos de la expedición del servidor'
      }
    }, processWithScandit);
  }

  private async processResponseGetExpeditionByFirstProduct(response: ExpeditionModel.ResponseCheckPackageForExpedition, writtenData: string, processWithScandit: boolean) {
    this.cleanViews();
    this.expeditionToRead = response.data;
    if(processWithScandit){
      ScanditMatrixSimple.hideLoadingDialog();
    } else {
      this.loadingMessageComponent.show(false);
    }

    this.dataToRead = '';
    if ((response.code === 200 || response.code === 201) && this.expeditionToRead) {
      await this.processFinishOk({
        focusInput: {
          playSound: true
        },
        toast: {
          position: PositionsToast.BOTTOM,
          message: `Producto ${writtenData} procesado.`,
          duration: TimesToastType.DURATION_SUCCESS_TOAST_2000
        }
      }, processWithScandit);
      if (this.expeditionToRead.hiringInfo) {
        if(processWithScandit){
          ScanditMatrixSimple.finish();
        }
        await this.processHiringInfo(this.expeditionToRead.hiringInfo);
      } else {
        this.showInitPageWithProductsScanned();
        if (this.expeditionToRead && this.expeditionToRead.packages) {
          for (const expeditionPackage of this.expeditionToRead.packages) {
            this.totalProducts += expeditionPackage.products.length;
            for (const product of expeditionPackage.products) {
              this.allProducts.push(product);
            }
          }
        }
        this.readedProducts.push(writtenData);
        for (const expeditionPackage of this.expeditionToRead.packages) {
          const packageReadedProducts = [];
          for (const product of expeditionPackage.products) {
            if (this.isProductReaded(product.barcode)) {
              packageReadedProducts.push(product);
            }
          }
          if (packageReadedProducts.length === expeditionPackage.products.length) {
            this.readedPackages.push(expeditionPackage);
          }
        }
        this.isScannerBlocked = false;
        const deliveryRequestExternalId = this.expeditionToRead.deliveryRequestExternalId;
        const processOver = this.checkIfProcessIsOver();
        if (processOver) {
          this.cleanExpeditionReaded();
          if(processWithScandit){
            ScanditMatrixSimple.showToast(`Ha escaneado todos los productos de la expedición del pedido ${deliveryRequestExternalId} pero ha ocurrido un error en el proceso en la comunicación con el servidor que no ha realizado la contratación.\n\nPor favor, inténtelo de nuevo o póngase en contacto con el administrador`, "#FFFFFF", "#eb445a", true);
            this.setScannerExpeditionInfo();
          } else {
            await this.intermediaryService.presentToastError(`Ha escaneado todos los productos de la expedición del pedido ${deliveryRequestExternalId} pero ha ocurrido un error en el proceso en la comunicación con el servidor que no ha realizado la contratación.\n\nPor favor, inténtelo de nuevo o póngase en contacto con el administrador`, PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST_LONG);
          }
        } else {
          if(processWithScandit){
            this.setScannerExpeditionInfo();
          }
        }
      }

    } else {
      this.isScannerBlocked = false;
      await this.processFinishError({
        focusInput: {
          playSound: true
        },
        toast: {
          position: PositionsToast.BOTTOM,
          message: response.errors ? response.errors : 'Ha ocurrido un error al intentar obtener los datos de la expedición'
        }
      }, processWithScandit);
    }
  }

  private async readProductWithExpedition(writtenData, processWithScandit: boolean) {
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `readProductWithExpedition`, {writtenData, processWithScandit})
    this.readedProducts.push(writtenData);
    this.readedPackages = [];
    for (const expeditionPackage of this.expeditionToRead.packages) {
      const packageReadedProducts = [];
      for (const product of expeditionPackage.products) {
        if (this.isProductReaded(product.barcode)) {
          packageReadedProducts.push(product);
        }
      }
      if (packageReadedProducts.length === expeditionPackage.products.length) {
        this.readedPackages.push(expeditionPackage);
      }
    }

    const deliveryRequestExternalId = this.expeditionToRead.deliveryRequestExternalId;
    const expeditionId = this.expeditionToRead.id;
    const productBarcodes = this.readedProducts;
    const processOver = this.checkIfProcessIsOver();
    if (processOver) {
      const expeditionProductsInfo = {
        expeditionId: expeditionId,
        productBarcodes: productBarcodes
      };
      if(processWithScandit){
        ScanditMatrixSimple.request("POST", `${environment.apiBase}/opl-expedition/check-expedition-products`, expeditionProductsInfo, localStorage.getItem("access_token"), "postExpeditionReadedProducts");
      } else {
        this.expeditionService.postExpeditionReadedProducts(expeditionProductsInfo).subscribe(async (response: ResponseCheckProductsForExpedition) => {
          await this.processResponsePostExpeditionReadedProducts(response, writtenData, deliveryRequestExternalId, processWithScandit);
        }, async (error) => {
          await this.processErrorPostExpeditionReadedProducts(error, processWithScandit);
        });
      }

    } else {
      if(processWithScandit){
        ScanditMatrixSimple.hideLoadingDialog();
      } else {
        this.loadingMessageComponent.show(false);
      }
      this.dataToRead = '';
      this.isScannerBlocked = false;
      await this.processFinishOk({
        focusInput: {
          playSound: true
        },
        toast: {
          position: PositionsToast.BOTTOM,
          message: `Producto ${writtenData} procesado.`,
          duration: TimesToastType.DURATION_SUCCESS_TOAST_2000
        }
      }, processWithScandit);
    }
  }

  private async processErrorPostExpeditionReadedProducts(error, processWithScandit: boolean) {
    if(processWithScandit){
      ScanditMatrixSimple.hideLoadingDialog();
    } else {
      this.loadingMessageComponent.show(false);
    }
    this.dataToRead = '';
    this.expeditionToRead = null;
    this.allProducts = [];
    this.totalProducts = 0;
    this.readedProducts = [];
    this.readedPackages = [];
    this.isScannerBlocked = false;
    await this.processFinishError({
      focusInput: {
        playSound: true
      },
      toast: {
        position: PositionsToast.BOTTOM,
        message: error && error.error && error.error.errors && typeof error.error.errors == 'string' ? error.error.errors : error && error.errors && typeof error.errors == 'string' ? error.errors : 'Ha ocurrido un error al intentar realizar la comprobación de productos con el servidor'
      }
    }, processWithScandit);
  }

  private async processResponsePostExpeditionReadedProducts(response: ExpeditionModel.ResponseCheckProductsForExpedition, writtenData: string, deliveryRequestExternalId: string, processWithScandit: boolean) {
    if(processWithScandit){
      ScanditMatrixSimple.hideLoadingDialog();
    } else {
      this.loadingMessageComponent.show(false);
    }

    this.isScannerBlocked = false;
    this.dataToRead = '';
    this.cleanExpeditionReaded();
    if (response.code === 200 || response.code === 201) {
      await this.processFinishOk({
        focusInput: {
          playSound: true
        },
        toast: {
          position: PositionsToast.BOTTOM,
          message: `Producto ${writtenData} procesado.`,
          duration: TimesToastType.DURATION_SUCCESS_TOAST_2000
        }
      }, processWithScandit);
      if (response.data && response.data.hiringInfo) {
        if(processWithScandit){
          ScanditMatrixSimple.finish();
        }
        await this.processHiringInfo(response.data.hiringInfo);
      } else {
        if(processWithScandit){
          ScanditMatrixSimple.showToast(`Ha escaneado todos los productos de la expedición del pedido ${deliveryRequestExternalId} pero ha ocurrido un error en el proceso en la comunicación con el servidor que no ha realizado la contratación.\n\nPor favor, inténtelo de nuevo o póngase en contacto con el administrador`, "#FFFFFF", "#eb445a", true);
          this.setScannerExpeditionInfo();
        } else {
          await this.intermediaryService.presentToastError(`Ha escaneado todos los productos de la expedición del pedido ${deliveryRequestExternalId} pero ha ocurrido un error en el proceso en la comunicación con el servidor que no ha realizado la contratación.\n\nPor favor, inténtelo de nuevo o póngase en contacto con el administrador`, PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST_LONG);
        }

      }
    } else {
      this.isScannerBlocked = false;
      await this.processFinishError({
        focusInput: {
          playSound: true
        },
        toast: {
          position: PositionsToast.BOTTOM,
          message: response.errors
        }
      }, processWithScandit);
    }
  }

  private async loadProductsByExpeditionId(expeditionId: number) {
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `loadProductsByExpeditionId`, {expeditionId})
    await this.intermediaryService.presentLoadingNew('Obteniendo expedición...');
    this.expeditionService.getExpeditionById(expeditionId).subscribe(async (response: ResponseCheckPackageForExpedition) => {
        await this.processResponseGetExpeditionById(response);
      }, async (error) => {
        await this.processErrorGetExpeditionById(error);
      }
    )
  }

  private async processErrorGetExpeditionById(error) {
    await this.intermediaryService.dismissLoadingNew();
    this.dataToRead = '';
    this.isScannerBlocked = false;
    await this.processFinishError({
      focusInput: {
        playSound: true
      },
      toast: {
        position: PositionsToast.BOTTOM,
        message: error && error.error && error.error.errors ? error.error.errors : 'Ha ocurrido un error al intentar obtener los datos de la expedición del servidor'
      }
    }, false);
  }

  private async processResponseGetExpeditionById(response: ExpeditionModel.ResponseCheckPackageForExpedition) {
    await this.intermediaryService.dismissLoadingNew();
    this.cleanViews();
    this.isScannerBlocked = false;
    this.focusToInput();
    this.expeditionToRead = response.data;
    if ((response.code === 200 || response.code === 201) && this.expeditionToRead) {
      if (this.expeditionToRead.hiringInfo) {
        await this.processHiringInfo(this.expeditionToRead.hiringInfo);
      } else {
        this.showInitPageWithProductsScanned();
        if (this.expeditionToRead && this.expeditionToRead.packages) {
          for (const expeditionPackage of this.expeditionToRead.packages) {
            this.totalProducts += expeditionPackage.products.length;
            for (const product of expeditionPackage.products) {
              this.allProducts.push(product);
            }
          }
        }
        const deliveryRequestExternalId = this.expeditionToRead.deliveryRequestExternalId;
        const processOver = this.checkIfProcessIsOver();
        if (processOver) {
          this.cleanExpeditionReaded();
          await this.intermediaryService.presentToastError(`Ha escaneado todos los productos de la expedición del pedido ${deliveryRequestExternalId} pero ha ocurrido un error en el proceso en la comunicación con el servidor que no ha realizado la contratación.\n\nPor favor, inténtelo de nuevo o póngase en contacto con el administrador`, PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST_LONG);
        } else {
          if (this.readMode === this.SCANNER_READ_MODE) {
            await this.startScanner();
          }
        }
      }
    } else {
      this.isScannerBlocked = false;
      await this.processFinishError({
        focusInput: {
          playSound: true
        },
        toast: {
          position: PositionsToast.BOTTOM,
          message: response.errors ? response.errors : 'Ha ocurrido un error al intentar obtener los datos de la expedición'
        }
      }, false);
    }
  }

  private focusToInput(playSound: boolean = false, typeSound: 'ok' | 'error' = 'ok', timeout: number = 10) {
    setTimeout(() => {
      if(document.getElementById('input-op')) {
        document.getElementById('input-op').focus();
      }
      if (playSound) {
        if (typeSound == 'ok') {
          this.audioProvider.playDefaultOk();
        } else {
          this.audioProvider.playDefaultError();
        }
      }
    }, 10);
  }

  public onFocus(event) {
    if (event && event.target && event.target.id) {
      this.keyboardService.setInputFocused(event.target.id);
    }
  }

  public isProductReaded(barcode) {
    return (this.readedProducts.indexOf(barcode) > -1);
  }

  private async processFinishOk(options: { toast?: { message: string, duration: number, position: string }, focusInput?: { playSound?: boolean }, playSound?: boolean } = null, processWithScandit: boolean) {
    if(processWithScandit){
      ScanditMatrixSimple.hideLoadingDialog();
    } else {
      this.loadingMessageComponent.show(false);
    }


    if (options.toast != null) {
      if(processWithScandit){
        ScanditMatrixSimple.showToast(options.toast.message, "#FFFFFF", "#2dd36f", false);
      } else {
        await this.intermediaryService.presentToastSuccess(options.toast.message, options.toast.duration, options.toast.position);
      }
    }

    if (options.focusInput != null) {
      if (options.focusInput.playSound) {
        if(processWithScandit){
          ScanditMatrixSimple.sound(1);
        } else {
          this.focusToInput(true, 'ok');
        }
      } else {
        if(!processWithScandit) this.focusToInput();
      }
    }

    if (options.playSound != null) {
      if(processWithScandit){
        ScanditMatrixSimple.sound(1);
      } else {
        this.audioProvider.playDefaultOk();
      }
    }
  }

  private async processFinishError(options: { toast?: { message: string, duration?: number, position: string }, focusInput?: { playSound?: boolean }, playSound?: boolean } = null, processWithScandit: boolean) {
    if (options.toast != null) {
      if(processWithScandit){
        ScanditMatrixSimple.showToast(options.toast.message, "#FFFFFF", "#eb445a", true);
      } else {
        await this.intermediaryService.presentToastError(options.toast.message, options.toast.position, options.toast.duration || TimesToastType.DURATION_ERROR_TOAST);
      }
    }

    if (options.focusInput != null) {
      if (options.focusInput.playSound) {
        if(processWithScandit){
          ScanditMatrixSimple.sound(2);
        } else {
          this.focusToInput(true, 'error');
        }
      } else {
        if(!processWithScandit) this.focusToInput();
      }
    }

    if (options.playSound != null) {
      this.audioProvider.playDefaultOk();
    }
  }

  private startScanner() {

    this.lastCodeScanned = "start";
    ScanditMatrixSimple.initExpeditionProducts(async (response) => {
      this.setScannerExpeditionInfo();
      let writtenData = null;
      if (response && response.result && response.actionIonic) {
        this.executeAction(response.actionIonic, response.params);
      } else if (response && response.barcode && !this.isScannerBlocked) {
        writtenData = response.barcode.data;
        await this.processCodeReadedToExpedition(writtenData, true);
      } else if (response && response.action == 'back') {
        this.changeReadModeFromScannerToLaserOrKeyboard(false);
      } else if (response && response.action == 'back-keyboard') {
        this.changeReadModeFromScannerToLaserOrKeyboard(true);
      } else if (response && response.action == 'request') {
        let responseData = null;
        if (response.data) {
          try {
            responseData = JSON.parse(response.data);
          } catch (e) {
          }
        }

        switch (response.requestType) {

          case "getExpeditionByFirstProduct":
            if ((response.code === 200 || response.code === 201) && responseData) {
              await this.processResponseGetExpeditionByFirstProduct(responseData, this.lastCodeReadedProcessed, true);
            } else {
              await this.processErrorResponseGetExpeditionByFirstProduct(responseData, true);
            }
            break;

          case "postExpeditionReadedProducts":
            const deliveryRequestExternalId = this.expeditionToRead ? this.expeditionToRead.deliveryRequestExternalId : '';
            if ((response.code === 200 || response.code === 201) && responseData) {
              await this.processResponsePostExpeditionReadedProducts(responseData, this.lastCodeReadedProcessed, deliveryRequestExternalId, true);
            } else {
              await this.processErrorPostExpeditionReadedProducts(responseData, true);
            }
            break;
        }
      }
			this.detectorRef.detectChanges();
    });
  }

  private setScannerExpeditionInfo() {
    let packages: { reference: string, products: { reference: string, processed: boolean }[] }[] = this.expeditionToRead && this.expeditionToRead.packages ? this.expeditionToRead.packages.map((p, i) => {
      return {
        reference: (i + 1).toString(), products: p.products.map((product) => {
          return {reference: product.barcode, processed: this.isProductReaded(product.barcode)};
        })
      };
    }) : [];
    let deliveryRequestExternalId: string = this.expeditionToRead ? this.expeditionToRead.deliveryRequestExternalId : '';
    let numPackagesProcessed: string = this.expeditionToRead && this.expeditionToRead.packages ? `Bultos ${this.readedPackages.length}/${this.expeditionToRead.packages.length}` : ``;
    let numProductsProcessed: string = this.expeditionToRead && this.expeditionToRead.packages ? `Productos ${this.readedProducts.length}/${this.totalProducts}` : ``;
    ScanditMatrixSimple.expeditionProductsLoadData(packages, deliveryRequestExternalId, numPackagesProcessed, numProductsProcessed);
  }

  private hideTextMessage(delay: number) {
    if (this.timeoutHideText) {
      clearTimeout(this.timeoutHideText);
    }
    this.timeoutHideText = setTimeout(() => {
      ScanditMatrixSimple.showText(false);
    }, delay);
  }

  showExpedition(finished: boolean) {
    this.close();
    this.StatusPrint = true;
    if (finished) this.showButtonReadNewExpedition = true;
  }

  showErrorExpedition(){
    this.close();
    this.PrintError =true;
  }

  scanningShippingLabels(){
    this.id = this.expeditionIdForCheckShippingLabels;
    this.showInputScanner();
  }

  async reprint(incidence?: boolean) {
    await this.sendServicePrintPack(this.expeditionId, true, incidence);
  }

  async reprintExtraDocs() {
    await this.printExtraDocs();
  }

  async processHiringInfo(hiringInfo: IExpeditionHiringInfo) {
    if (!(hiringInfo && hiringInfo.expedition)) return false;
    this.hiringInfoProcessed = hiringInfo;
    this.numPackages = hiringInfo.expedition.packages.length;
    this.expeditionId = hiringInfo.expedition.id;
    this.expeditionIdForCheckShippingLabels = hiringInfo.expedition.id;
    this.labelsService.shippingPackageCodes = hiringInfo.shippingPackageCodes;
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `processHiringInfo`, {printInfoQuantity: hiringInfo && hiringInfo.printInfo ? hiringInfo.printInfo.length : false, incidence: hiringInfo.incidence})
    if (hiringInfo.incidence) {
      this.showErrorExpedition();
    } else {
      this.showExpedition(false);
    }
    try {
      await this.processFilesToPrint(hiringInfo.printInfo, false);
    } catch (e) {
      console.error(e)
    }
  }

  async sendServicePrintPack(id, reprint?, incidence?) {
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, 'sendServicePrintPack', {id, reprint, incidence})
    let body = {expeditionId: id, setAsImpress: true, reprint: reprint, incidence: incidence, all: false};

    /*
    * When this.incidenceResolved = true we are querying docs for a expedition with incidence resolved, so we could
    *  need to reprint some docs associated to the expedition.
    * If reprint = false means that this process was threw automatically at enter in the expeditions screen after scan
    *  the incidence code for expedition with incidence resolved, so we will need to reprint all the docs associated
    *  to the expedition.
    * */
    if (this.incidenceResolved) {
      if (!reprint) {
        body.all = true;
      }
      body.reprint = true;
    }

    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, 'postServicePrintPack', body)
    await this.labelsService.postServicePrintPack(body)
      .subscribe(async (result: LabelModel.IExpeditionDocToPrint[]) => {
        this.log(`PRINT_EXPEDITION_${this.expeditionId}`, 'postServicePrintPack response', result)
        let isMobileApp = typeof (<any>window).cordova !== "undefined";
        if (isMobileApp == true) {
          if (!reprint) {
            this.loadingDownloadExpeditionIdShown = id;
            setTimeout(() => {
              if (this.loadingDownloadExpeditionIdShown == id) {
                this.log(`PRINT_EXPEDITION_${this.expeditionId}`, 'DOWNLOAD TIMEOUT: 30 seconds elapsed without finishing the download.', {id});
                this.intermediaryService.dismissLoadingNew();
                this.intermediaryService.presentToastError("Error al descargar etiqueta");
              }
            }, 30000);
            await this.intermediaryService.presentLoadingNew("Descargando archivo...");
          }

          await this.printerServiceService.getPrinterServiceAddress();
          await this.processFilesToPrint(result, reprint);
        }
      }, async (err) => {
        this.log(`PRINT_EXPEDITION_${this.expeditionId}`, 'postServicePrintPack error', err)
        await this.intermediaryService.presentToastError("No se pudo descargar el archivo");
      });
  }

  private async processFilesToPrint(labels: LabelModel.IExpeditionDocToPrint[], reprint: boolean) {
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `processFilesToPrint`, {labelsQuantity: labels.length, reprint: reprint})
    await this.printerServiceService.getPrinterServiceAddress();
    for (const iExpeditionDoc in labels) {
      const expeditionDoc = labels[iExpeditionDoc];
      const currentItem: number = parseInt(iExpeditionDoc) + 1;
      this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `Printing item ${currentItem} of ${labels.length} started.`, {currentItem})

      if (expeditionDoc.extension == 'pdf') {
        const bodyPrintBase64: PrinterServiceModel.ParamsPrintBase64 = {
          documentBase64: expeditionDoc.document,
          documentType: expeditionDoc.type
        };
        this.printerServiceService.postPrintBase64(bodyPrintBase64).subscribe(res => {
          this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `postPrintBase64 success`, res)
        }, error => {
          this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `postPrintBase64 error`, error)
          this.intermediaryService.presentToastError("Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
        });
      } else {
        const bodyPrint: PrinterServiceModel.ParamsPrint = {
          document: expeditionDoc.document,
          documentType: expeditionDoc.type
        };
        this.printerServiceService.postPrint(bodyPrint).subscribe(res => {
          this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `postPrint success`, res)
        }, error => {
          this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `postPrint error`, error)
          this.intermediaryService.presentToastError("Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
        });
      }
      this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `Download item ${currentItem} of ${labels.length} started.`, {currentItem})
      const urlDownload = environment.downloadPdf + '/' + expeditionDoc.externalPath;
      this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `getExpeditionDownloadedFileName`, {extension: expeditionDoc.extension, expeditionDeliveryRequest: expeditionDoc.expeditionDeliveryRequest})
      const fileName = this.downloaderService.getExpeditionDownloadedFileName(expeditionDoc.extension, expeditionDoc.expeditionDeliveryRequest);
      this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `getExpeditionDownloadedFileName fileName`, {fileName})
      if (!reprint) {
        if (parseInt(iExpeditionDoc) == (labels.length - 1)) {
          this.downloadUrl(urlDownload, fileName, true);
        } else {
          this.downloadUrl(urlDownload, fileName, false);
        }
      }
    }
    /*
    * Filter and store the extra-docs loaded for re-print of it has not been stored before
    * */
    if (!this.expeditionExtraDocs.length) this.expeditionExtraDocs = labels.filter(r => r.isExtraDoc) || [];
  }

  async downloadUrl(urlDownload,name,isFinish){
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `downloadUrl`, {urlDownload, name, isFinish})
    let request = this.notificationDownload(urlDownload,name);
    this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `downloadUrl request`, {request})
      await this.downloader.download(request).then((location: string) =>{
        this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `downloadUrl download success`, {name, location})
          if(isFinish == true && this.loadingDownloadExpeditionIdShown){
            this.loadingDownloadExpeditionIdShown = 0;
            this.intermediaryService.dismissLoadingNew();
            this.intermediaryService.presentToastSuccess("Descarga exitosa...");
          }
          }).catch((error: any) => {
            this.log(`PRINT_EXPEDITION_${this.expeditionId}`, `downloadUrl download error`, {name, error})
            if(isFinish == true && this.loadingDownloadExpeditionIdShown){
              this.loadingDownloadExpeditionIdShown = 0;
              this.intermediaryService.dismissLoadingNew();
              this.intermediaryService.presentToastError("Error al descargar etiqueta");
            }
          });
  }

  notificationDownload(url,name): DownloadRequest {
    const downloadsDirectory = `${config.downloads.directoryBase}/${config.downloads.directoryAL}/${config.downloads.directoryExpeditions}`;
    return {
      uri: url,
      title: 'Etiqueta de expedición',
      description: '',
      mimeType: '',
      visibleInDownloadsUi: true,
      notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
      destinationInExternalPublicDir: {
        dirType: downloadsDirectory,
        subPath: name
      }
    }
  }

  getTextNumberShippingLabelsToCheck() {
    return `${(this.labelsService.getNumShippingPackageCodesChecked() + 1)} de ${this.labelsService.getNumShippingPackageCodes()}`;
  }

  goToPageIncidences(){
    this.close();
    this.initPage = true;
    this.cleanAll();
    this.router.navigateByUrl('/list-alerts');
  }

  close(){
    this.cleanViews();
  }

  showInitPage(){
    this.cleanViews();
    this.cleanAll();
    this.initPage = true;
  }

  showInitPageWithProductsScanned(){
    this.cleanViews();
    this.initPage = true;
  }

  cleanExpeditionReaded(){
    this.expeditionToRead = null;
    this.allProducts = [];
    this.totalProducts = 0;
    this.readedProducts = [];
    this.readedPackages = [];
    this.isScannerBlocked = false;
  }

  cleanViews(){
    this.StatusPrint = false;
    this.initPage = false;
    this.PrintError =false;
    this.showButtonReadNewExpedition = false;
    this.viewInput = false;
  }

  cleanAll(){
    //Reset variables expedition readed
    this.expeditionToRead = null;
    this.allProducts = [];
    this.totalProducts = 0;
    this.readedProducts = [];
    this.readedPackages = [];
    this.isScannerBlocked = false;
    this.hiringInfoProcessed = null;
    this.expeditionIdForCheckShippingLabels = 0;

    this.expeditionId = 0;
    this.numPackages=0;
    this.expeditionExtraDocs = [];
    this.labelsService.shippingPackageCodes = [];
  }

  async returnToReadNewExpedition(){
    this.close();
    this.initPage = true;
    this.cleanAll();
    if (this.lastReadMode == this.SCANNER_READ_MODE) {
      await this.startScanner();
    }
  }

  async goToReadNewExpedition(){
    this.close();
    this.initPage = true;
    this.cleanAll();
    this.router.navigateByUrl('/order-preparation');
    if (this.lastReadMode == this.SCANNER_READ_MODE) {
      await this.startScanner();
    }
  }

  ngOnDestroy(){
    this.close();
    this.cleanAll();
  }

  showInputScanner(){
    this.authenticationService.isStoreUser().then(async isStoreUser => {
      if(isStoreUser){
        this.close();
        this.camera = true;
        this.viewInput = true;
      }else{
        this.close();
        this.camera = false;
        this.viewInput = true;
      }
    });
  }

  async showExpeditionFinished(state: { code: string, valid: false, finished: false }) {
    if (state && state.valid && !state.finished) {
      await this.intermediaryService.presentToastSuccess(`Etiqueta de envío [${state.code}] procesada`, TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM);
      this.audioProvider.playDefaultOk();
      this.showExpedition(false);
    } else if (state && state.valid && state.finished){
      await this.intermediaryService.presentToastSuccess(`Etiqueta de envío [${state.code}] procesada y expedición indicada como pendiente de recodiga`, TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM);
      this.audioProvider.playDefaultOk();
      this.showExpedition(true);
    } else if(state && !state.valid && !state.finished){
      this.audioProvider.playDefaultError();
      await this.intermediaryService.presentToastError(`El código de envío escaneado [${state.code}] no se encuentra entre los códigos de envío generados para esta expedición`, PositionsToast.BOTTOM, TimesToastType.DURATION_SUCCESS_TOAST_4550);
      this.showExpedition(false);
    } else {
      this.audioProvider.playDefaultError();
      await this.intermediaryService.presentToastError('Ha ocurrido un error al realizar la comprobación de códigos de envío y la expedición no se ha podido marcar como pendiente de ser recogida.\n\nPor favor, vuelva a escanear las etiquetas de envío o póngase en contacto con la central.', PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST_LONG);
      this.labelsService.resetCheckedShippingPackageCodes();
      this.showExpedition(false);
    }
  }

  /*
  * Throw to print-micro-service the print of docs stored as extra-documents or documents to not scan associated to
  *   the expedition loaded.
  * */
  public async printExtraDocs() {
    if (typeof this.expeditionExtraDocs == "undefined" || this.expeditionExtraDocs == null || !this.expeditionExtraDocs.length) {
      await this.intermediaryService.presentToastError("[ERR1904] Ha ocurrido un error al intentar re-imprimir la documentación del pedido.", PositionsToast.BOTTOM);
      return;
    }

    await this.printerServiceService.getPrinterServiceAddress();

    for (let extraDoc of this.expeditionExtraDocs) {
      if (extraDoc.extension == 'pdf') {
        const bodyPrintBase64: PrinterServiceModel.ParamsPrintBase64 = {
          documentBase64: extraDoc.document,
          documentType: extraDoc.type
        };
        this.printerServiceService.postPrintBase64(bodyPrintBase64).subscribe(res => {
          console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);
        }, error => {
          console.error('PrinterService::ERROR to print item: ', JSON.stringify(error));
          this.intermediaryService.presentToastError("[ERR1905] Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
        });
      } else {
        const bodyPrint: PrinterServiceModel.ParamsPrint = {
          document: extraDoc.document,
          documentType: extraDoc.type
        };
        this.printerServiceService.postPrint(bodyPrint).subscribe(res => {
          console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);
        }, error => {
          console.error('PrinterService::ERROR to print item: ', JSON.stringify(error));
          this.intermediaryService.presentToastError("[ERR1906] Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
        });
      }
    }
  }

  private executeAction(action: string, paramsString: string){
    let params = [];
    try{
      params = JSON.parse(paramsString);
    } catch (e) {
    }
    switch (action){
      case 'lastCodeScannedStart':
        this.lastCodeScanned = 'start';
        break;
    }
  }

  private log(tag: string, text: string, data: object){
    console.debug(`[${new Date().toJSON()}] ${tag} - ${text}`, data);
  }
}
